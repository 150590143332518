import {useHistory} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {CompanyType} from "../../../types/company.type";
import customAxios from "../../../service/api/interceptors";
import moment from "moment";
import Select from "../../../components/UI/Select/Select";
import {PlatformDateRangePicker} from "../../../components/UI/DateRangePicker/DateRangePicker";
import {dateMonthAgo, followUpCallTypesArray} from "./FollowUpList";

export const FollowUpListFilter: React.FC<{ isClient: boolean }> = ({ isClient }) => {
    const history = useHistory()
    const searchParams = new URLSearchParams(window.location.search);
    const [loading, setLoading] = useState(false)
    const [companies, setCompanies] = useState<CompanyType[]>([])

    const updateParam = (paramName: string, newValue: string) => {
        if (searchParams.has(paramName)) {
            searchParams.set(paramName, newValue);
        } else {
            searchParams.append(paramName, newValue);
        }
        searchParams.set("page", '1')
        history.push(`${window.location.pathname}?${searchParams.toString()}`);
    }

    const getData = async () => {
        setLoading(true)
        try {
            const { status: companyStatus, data: companyData } =
                await customAxios.get(`/company/company/get-companies`)
            if (companyStatus === 200) {
                setCompanies(companyData)
            }
        } catch (e) {}
        setLoading(false)
    }

    useEffect(() => {
        document.title = "Calls & Follow-ups"
        if (!isClient) getData().then()
    }, [])

    const updateDatePickerValue = (range: { startDate: Date, endDate: Date }) => {
        if (moment(searchParams.get("start_date") || new Date()).format("DD-MM-YYYY") !== moment(range.startDate).format("DD-MM-YYYY")) {
            updateParam("start_date", String(+range.startDate))
        }

        if (moment(searchParams.get("end_date") || new Date()).format("DD-MM-YYYY") !== moment(range.endDate).format("DD-MM-YYYY")) {
            updateParam("end_date", String(+range.endDate))
        }
    }

    if (loading) return <></>

    return (
        <div className={"follow-up-list-filter flex"}>
            {!isClient && <Select
                className={"follow-up-list__filter-select mr10"}
                topLabel={"Company"}
                value={searchParams.get("company_id") || ""}
                options={[
                    {value: "", label: "Not selected"},
                    ...companies.map(el => ({value: el.id, label: el.name}))
                ]}
                onChange={e => updateParam("company_id", e.value)}
            />}
            <Select
                className={"follow-up-list__filter-select mr10"}
                topLabel={"Call type"}
                value={searchParams.get("call_type") || ""}
                options={[
                    { value: "", label: "Not selected" },
                    ...followUpCallTypesArray
                ]}
                onChange={e => updateParam("call_type", e.value)}
            />
            <PlatformDateRangePicker
                label={"Date"}
                startDate={searchParams.has("start_date") ? new Date(Number(searchParams.get("start_date") || 0)) : dateMonthAgo}
                endDate={searchParams.has("end_date") ? new Date(Number(searchParams.get("end_date") || 0)) : new Date()}
                onChange={updateDatePickerValue}
            />
        </div>
    )
}