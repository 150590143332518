import React, {useContext, useEffect, useState} from "react";
import './OfferFooter.scss'
import {MainContextProvider} from "../../../../context/MainContext";
import customAxios from "../../../../service/api/interceptors";
import {CreditControlModal} from "../../../../containers/Modals/Credits/CreditControlModal";

type OfferFooterType = {
    title?: string
    totalPrice: number
    totalCreditPrice: number
    accepted: 1 | 0
    onClick: () => void
    copyGeneratedLink?: () => void
    offlineButton?: () => void
    ifOfflineButton?: boolean
    is_done?: number
    isUnSubscribe: boolean
    updateOffer?: () => void
    moveToPayment?: (status: string) => void,
    pr_id?: number
    payTaskApprove?: boolean
    payTask?: () => void
    setHaveToBeApproveTasksModal?: (key: boolean) => void
    is_unpaid_tasks?: boolean
}


export const OfferFooter: React.FC<OfferFooterType> = ({ is_unpaid_tasks, setHaveToBeApproveTasksModal, payTask, payTaskApprove, title, totalPrice, totalCreditPrice, is_done, accepted, onClick, copyGeneratedLink, offlineButton, ifOfflineButton, isUnSubscribe, pr_id, updateOffer, moveToPayment }) => {
    const { userData, setLoading, creditCount, getCreditCount } = useContext(MainContextProvider)
    const [haveToBeApproveModal, setHaveToBeApproveModal] = useState(false)

    const choosePr = async () => {
        setLoading(true)
        try {
            const { status, data } = await customAxios.put(`/partner-request/${pr_id}/client-accepted`)
            if (status === 200 && updateOffer) {
                updateOffer()
                setHaveToBeApproveModal(false)
            }
        } catch (e) {}
        setLoading(false)
    }

    useEffect(() => {
        if (userData?.status.type_code === 100) getCreditCount()
    },[])

    return (
        <div className={'offer-footer'}>
            {payTaskApprove && <CreditControlModal
                submit={payTask ? payTask : () => {}}
                close={() => setHaveToBeApproveTasksModal ? setHaveToBeApproveTasksModal(false) : () => {}}
                creditsSum={totalCreditPrice}
                commonTitle={title || ''}
                noCreditsSubtitle={`INSUFFICIENT CREDITS`}
                noCreditsDescription={`This request requires ${totalCreditPrice} Credits. You do not have enough Credits to proceed. You can purchase more Credits now and then go to your Legal Roadmap to submit this request.`}
                acceptTitle={`${totalCreditPrice} credits will be charged TO PROCEED WITH THIS REQUEST.`}
                continueButtonTitle={'CONTINUE'}
            />}

            {haveToBeApproveModal && <CreditControlModal
                submit={() => {
                    if (pr_id && moveToPayment) {
                        choosePr().then()
                        //moveToPayment('3')
                    } else {
                        onClick()
                        setHaveToBeApproveModal(false)
                    }
                }}
                close={() => setHaveToBeApproveModal(false)}
                creditsSum={totalCreditPrice}
                commonTitle={title || ''}
                noCreditsSubtitle={`INSUFFICIENT CREDITS`}
                noCreditsDescription={`This request requires ${totalCreditPrice} Credits. You do not have enough Credits to proceed. You can purchase more Credits now and then go to your Legal Roadmap to submit this request.`}
                acceptTitle={`${totalCreditPrice} credits will be charged TO PROCEED WITH THIS REQUEST.`}
                continueButtonTitle={'CONTINUE'}
            />}
            <div className="offer-footer__left">
                {userData?.status.type_code === 300 && +accepted === 0 && <div>
                    Clicking “PUBLISH” will send the all lists of tasks and provided estimates to the client for review.
                </div>}
            </div>
            <div className={`offer-footer__right ${userData?.status.type_code === 100 && 'w100'}`}>
                {(+totalPrice > 0 || creditCount.total_count) > 0 && <div className="offer-footer__price" style={{ display: "flex", alignItems: "flex-end", flexDirection: "column" }}>
                    <span className={''}>LN Credits: <b>{totalCreditPrice}</b></span>
                    <span className={"mt5"}>Provider Cost: <b>{'$' + totalPrice}</b></span>
                </div>}
                <div className={'w100'}>
                    {userData?.status.type_code === 100 && is_unpaid_tasks
                        //&& +accepted !== 2
                        && <button
                        onClick={() => setHaveToBeApproveTasksModal ? setHaveToBeApproveTasksModal(true) : () => {}}  className={'offer-footer__button w100'}>
                        Pay tasks
                    </button>}
                    {userData?.status.type_code === 100 && <button
                        title={isUnSubscribe ? 'You do not have an active Monthly Plan so this feature is unavailable. Please activate the Monthly Plan to unlock this feature.' : ''}
                        onClick={() => {
                            if (creditCount.isCreditSubscription && +totalCreditPrice === 0) {
                                if (pr_id && moveToPayment) {
                                    choosePr().then()
                                    //moveToPayment('3')
                                } else {
                                    onClick()
                                    setHaveToBeApproveModal(false)
                                }
                            } else {
                                setHaveToBeApproveModal(true)
                            }
                        }} disabled={(+accepted !== 2) || isUnSubscribe} className={'offer-footer__button w100'}>
                        I accept{+accepted !== 2 && 'ed'} this offer
                    </button>}
                    {userData?.status.type_code === 300 && +accepted === 2 &&
                    <button
                        title={isUnSubscribe ? 'You do not have an active Monthly Plan so this feature is unavailable. Please activate the Monthly Plan to unlock this feature.' : ''}
                        disabled={isUnSubscribe}
                        style={{ marginLeft: '10px' }} onClick={copyGeneratedLink} className={'offer-footer__button'}>
                        Copy link
                    </button>}
                    {offlineButton && <button disabled={ifOfflineButton || isUnSubscribe} onClick={offlineButton} className={'offer-footer__button'}>
                        I ACCEPT{ifOfflineButton || isUnSubscribe && 'ED'} THIS OFFER
                    </button>}
                </div>
            </div>
        </div>
    )
}