import React, {useContext, useEffect, useState} from "react";
import {MainContextProvider} from "../../../context/MainContext";
import {CommentsContainer} from "../../../components/other/CommentsContainer/CommentsContainer";
import {OfferHeader} from "../../../components/other/ClientKanbanData/OfferHeader/OfferHeader";
import {OfferHeading} from "../../../components/other/ClientKanbanData/OfferHeading/OfferHeading";
import {LegalTeam} from "../../../components/other/ClientKanbanData/LegalTeam/LegalTeam";
import {TaskTabList} from "../../../components/other/ClientKanbanData/TaskTabList/TaskTabList";
import Tabs from "../../../components/other/Tabs";
import {OfferFooter} from "../../../components/other/ClientKanbanData/OfferFooter/OfferFooter";
import {
    acceptOfferService,
    getSingleOfferService,
    sendKanbanOfferCommentService, updateOfferDeliverablesService,
    updateOfferService
} from "../../../service/client/offer";
import Deliverables from "../Task/TaskView/other/Deliverables";
import { DeliverablesTab } from "../../../components/other/ClientKanbanData/Deliverables/DeliverablesTab";
import {switchNextSteps} from "../../Lawyer/Task/TasksProjectKanban/TasksProjectKanbanGrid/TasksProjectKanbanGrid";
import {useHistory, useLocation} from "react-router-dom";
import {deleteSingleTask} from "../../../service/tasks";
import customAxios from "../../../service/api/interceptors";
import bottomIcon from "../../../assets/images/backBlackIcon.svg";
import pushNotifications from "../../../helpers/pushNotifications";
import {toastifyError, toastifySuccess} from "../../../service/toastify/toastify";
import './singleOffer.scss'
import {DeliverablesTaskItems} from "./DeliverablesTaskItems";
import {ClientKanbanStepNotifications} from "../../../components/other/ClientKanbanBoard/ClientKanbanStepNotifications/ClientKanbanStepNotifications";
import {TaskType} from "../../../types/task.type";
import {FrozenController} from "../../../components/other/FrozenController/FrozenController";
import {ModalLoader} from "../../../components/other/ModalLoader/ModalLoader";
import {useIntercom} from "react-use-intercom";
import {intercomCatchErrorMessage} from "../../../helpers/intercomCatchErrorMessage";
import nodeIcon from '../../../assets/images/stepsIcons/node.svg'
import waitIcon from '../../../assets/images/stepsIcons/wait-white.svg'
import clockIcon from '../../../assets/images/stepsIcons/clock.svg'
import doneIcon from '../../../assets/images/stepsIcons/done-white.svg'
import cursorIcon from '../../../assets/images/stepsIcons/cursorWhite.svg'
import draftIcon from '../../../assets/images/stepsIcons/draft.svg'
import emailIcon from '../../../assets/images/kanbanIcons/whiteEmailSendIcon.svg'
import {VloPartnerRequest} from "./ccomponents/VloPartnerRequest/VloPartnerRequest";
import {
    OfferAndPartnerRequestTabs
} from "../../../components/other/OfferAndPartnerRequestTabs/OfferAndPartnerRequestTabs";
import {OfferClientAccepted} from "./ccomponents/OfferClientAccepted";
import {TaskDeliverablesForOffer} from "./ccomponents/TaskDeliverablesForOffer/TaskDeliverablesForOffer";
import {GroupComments} from "../../../components/other/GroupComments/GroupComments";
import readMoreIcon from "../../../assets/images/icons/readMoreBlue.svg";
import {useTimer} from "../../../context/TimerContext";

export const offerStatus = (offerData: any) => {
    if(offerData['is done'] === 1) {
        return {
            text: 'Done',
            color: '#FFFFFF',
            background: '#5D8C51',
            icon: doneIcon
        }
    }
    if(+offerData?.accepted === 0 ) {
        return {
            text: 'DRAFT',
            color: '#000000',
            background: '#FFFFFF',
            icon: draftIcon
        }
    }
    if(+offerData?.accepted === 1 ) {
        return {
            text: 'PROJECT APPROVED',
            color: '#FFF',
            background: '#F3B71B',
            icon: cursorIcon
        }
    }
    if(+offerData?.accepted === 3) {
        return {
            text: 'AWAITING PAYMENT',
            color: '#FFFFFF',
            background: '#F3B71B',
            icon: cursorIcon
        }
    }
    if(+offerData?.accepted === 2 ) {
        return {
            text: 'ESTIMATES SENT TO THE CLIENT',
            color: '#FFF',
            background: '#000',
            icon: emailIcon
        }
    }
    if(offerData?.accepted === 5) {
        return {
            text: 'Work In-Progress',
            color: '#FFFFFF',
            background: '#2361FF',
            icon: waitIcon
        }
    }
    return {
        text: '-',
        color: '#2361FF',
        background: '#2361FF'
    }
}

export const Offer: React.FC<any> = ({ match }) => {
    const [loading, setContainerLoading] = useState(true)
    const { params: { id } } = match
    const history = useHistory()
    const { search } = useLocation()
    const { setLoading, userData, modalData, setModalType, setModalData, setHeaderTimer, headerTimer, setCurrentTimer, currentTimer} = useContext(MainContextProvider)
    const [isAcceptedOffer, setIsAcceptedOffer] = useState(false)
    const [isHideContent, setIsHideContent] = useState(false)
    const [offerData, setOfferData] = useState<any>({})
    const [notifications, setNotifications] = useState<any[]>([])
    const [tasks, setTasks] = useState<any>([])
    const [isUnSubscribe, setIsUnSubscribe] = useState(false)
    const [fullScreenChat, setFullScreenChat] = useState(false)
    const [activeIndex, setActiveIndex] = useState(0)
    const [updateChatIndex, setUpdateChatIndex] = useState(0)
    const [chatCount, setChatCount] = useState(0)
    const { setStartedTimerData } = useTimer();


    useEffect(() => {
        setLoading(false)
    }, [])

    const [isEditModalWasOpened, setIsEditModalWasOpened] = useState(false)
    const { showNewMessages } = useIntercom();
    const getOffer = async () => {
        setContainerLoading(true)
        try {
            const { data, status } = await getSingleOfferService(id)
            if(status === 200) {
                await getAllDeliverables()

                setOfferData({
                    ...data.offer,
                    client: data.clients,
                    company: data.company,
                    ['is done']: data['is done'],
                    offerComments: (data.offer.offerComments || []).reverse(),
                    is_unpaid_tasks: data.is_unpaid_tasks === 1
                })

                const timerData = {
                    company_id: data.company.id,
                    offer_id: data.offer.id,
                    company_name: data.company.name,
                    type: ` Communication re Request #${data?.offer?.id}`,
                    created_at: null,
                    timer_id: null
                }

                setStartedTimerData(timerData)

                if (!headerTimer && currentTimer !== "loading") {
                    setHeaderTimer(timerData)
                }

                if (+data.offer.accepted > 1) {
                    setIsHideContent(true)
                }

                setIsUnSubscribe(data.company.debt === 'unsubscribed')
                document.title = data.company?.name + " - " + data.offer?.title
                setNotifications([...data.notifications.seen, ...data.notifications.unseen].sort(function (a, b) {
                    if (a.id > b.id) {
                        return 1;
                    }
                    if (a.id < b.id) {
                        return -1;
                    }
                    return 0;
                }).reverse())
                setTasks(data.tasks)
                if(userData?.status.type_code === 300 && +data?.offer.accepted === 2 ) generateTokenForOffer().then()

                if(window.location.search === '?edit=true' && !isEditModalWasOpened) {
                    setModalData({
                        id,
                        company_id: data?.company?.id, step: 2,
                        title: data.offer.title, description: data.offer.description,
                        configTitle: 'Update offer'
                    })
                    setModalType('create_manager_offer')
                    setIsEditModalWasOpened(true)
                }
            }
        } catch (error) {
            // @ts-ignore
            console.log('error', error, error?.response)
            await showNewMessages(intercomCatchErrorMessage(error))
        }
        setContainerLoading(false)
    }

    useEffect(() => {
        if (!currentTimer && !headerTimer && Object.keys(offerData).length > 0) {
            const timerData = {
                company_id: offerData?.company?.id,
                company_name: offerData?.company?.name,
                offer_id: offerData?.id,
                type: `Communication re Request #${offerData?.id}`,
                created_at: null,
                timer_id: null
            }

            if (!headerTimer && currentTimer !== "loading") {
                setHeaderTimer(timerData)
            }
        }
    }, [currentTimer, headerTimer, offerData])

    const acceptOffer = async () => {
      if(offerData?.accepted !== 2) {
          return false;
      }
        setContainerLoading(true)
      const { data, status } = await acceptOfferService(id)
        await getOffer()
        setIsAcceptedOffer(true)
        setContainerLoading(false)
        setActiveIndex(0)
    }

    const moveToPayment = async (toStatus: string) => {
        setContainerLoading(true)
        try {
            const { status, data } = await updateOfferService({
                title: offerData.title, description: offerData.description, accepted: toStatus
            }, id)

            if (offerData.is_done) {
                await customAxios.post(`/company/offer/done`, { offer_id: id })
            }

            if(status === 200 || status === 201) {
                await getOffer()
            }
        } catch (e) {

        }
        setContainerLoading(false)
    }

    const moveToDone = async () => {
        const { status, data } = await customAxios.post(`/company/offer/done`, { offer_id: id })
        const {  } = await updateOfferService({
            title: offerData.title, description: offerData.description, accepted: '5'
        }, id)
        if(status === 200) {
            await getOffer()
        }
    }

    const moveToUnDone = async () => {
        const { status } = await customAxios.post(`/company/offer/undone`, { offer_id: id })
        if(status === 200) {
            await getOffer()
        }
    }

    const [outsideOfferLink, setOutsideOfferLink] = useState('')
    const generateTokenForOffer = async () => {
        const { status, data } = await customAxios.get(`/company/offer/get-offer-view-token?offer_id=${id}`)
        if(status === 200) {
            setOutsideOfferLink(`${process.env.REACT_APP_SITE_LINK}/offline-offer/${id}?token_view=${data}`)
        }
    }

    const [deliverables, setDeliverables] = useState<{description: string, id: number, name: string, task_id: number, url: string}[]>([])
    const getAllDeliverables = async () => {
        const { status, data } = await customAxios.get(`/company/deliverable/get-by-offer?id=${id}&expand=task`)
        if(status === 200) {
            if (Array.isArray(data)) {
                setDeliverables(data)
            } else {
                setDeliverables([])
            }
        }
    }


    useEffect(() => {
        setIsAcceptedOffer(false)
        getOffer().then()
        if (window.location.search.includes('pr')) {
            setActiveRequestTab('pr')
        } else setActiveRequestTab('offer')

    }, [search, id])

    useEffect(() => {
        if(modalData?.update) {
            getOffer().then()
            setModalData(null)
        }
    }, [modalData])

    const deleteTask = async (id: number) => {
        setContainerLoading(true)
        try {
            const { status } = await deleteSingleTask(id)
            if(status === 204) {
                await getOffer()
            }
        } catch (e) {
            await showNewMessages(intercomCatchErrorMessage(e))
        }
        setContainerLoading(false)
    }

    const copyOfferInfoToBuffer = () => {
        let desc = offerData.description.replace(/<li>/gi, '<li>- ')

        const txt = `Offer: ${offerData.title}
Description: ${desc.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '')}
${tasks.map((item: any) => `
${item?.provider ? `Provider: ${item?.provider?.fullname}\n` : `Tasks:\n`}
${item.tasks.map((task: any) =>
`${task.title}
- Price Estimate: $${task.price_per_hour ? `${task.price_per_hour}/hour` : task.price_estimate}
- Time Estimate: ${task.price_per_hour ? '-' : task.time_estimate} days
`
).join('')}
`).join('')}
If this works for you then we will get started with these tasks. Looking forward for your reply.`

        console.log(txt)
        navigator.clipboard.writeText(txt)
    }

    const moveToArchive = async (key: number) => {
        setLoading(true)
        try {
            const { status, data } = await customAxios.put(`company/offers/${id}`, { is_archived: key })
            if (status === 200) {
                history.push(key === 1 ? `/archive` : `/dashboard`)
            }
        } catch (e) {}
        setLoading(false)
    }

    const createGeneralChat = async () => {
        setLoading(true)
        try {
            const { data: prItems } = await customAxios.get(`/partner-request/offer/${id}`)
            if ((prItems || []).find((el: { status: string }) => +el.status === 5)) {
                const { status, data } = await customAxios.post(`/chat/offer/${id}/general`)
                if (status === 200) {
                    setUpdateChatIndex(prevState => prevState + 1)
                }
            } else {
                toastifyError('The client must accept a minimum of 1 partner request')
            }

        } catch (e) {}
        setLoading(false)
    }

    const [haveToBeApproveModal, setHaveToBeApproveModal] = useState<boolean>(false)
    const payTasks = async () => {
        setLoading(true)
        try {
            const { status, data } = await customAxios.post(`/company/offer/${offerData.id}/pay-tasks`)
            if (status === 200) {
                await getOffer()
                setHaveToBeApproveModal(false)
            }
        } catch (e) {}
        setLoading(false)
    }


    const [activeRequestTab, setActiveRequestTab] = useState<'offer' | 'pr'>('offer')
    const [tabsCounter, setTabsCounter] = useState({ offers: 0, pr: 0 })

    return (
        <div style={{ position: 'relative' }} className={`${fullScreenChat && 'full-screen-chat'}`}>
            {loading && <ModalLoader />}
            <FrozenController debt={offerData?.company?.debt} />
            <div onClick={() => history.goBack()}
                 className={'mobile-back-to-all-task'}>
                <img src={bottomIcon} alt=""/>
                ALL OFFERS
            </div>
            <div className={'singleOffer task-view'}>
                <div className="task-view__left-side">
                    {!loading &&
                        <OfferAndPartnerRequestTabs activeRequestTab={activeRequestTab}
                            setActiveRequestTab={(key: any) => {
                                if (key === 'offer' && window.location.search.includes('pr')) {
                                    history.push(`/offer/${id}`)
                                }
                                setActiveRequestTab(key)
                            }} offer_id={id}
                            active={'offer'}
                        />
                    }
                    {activeRequestTab === "pr" && <div>
                        <VloPartnerRequest offer_id={id} offerData={offerData}
                                           setTabsCounter={(count) => setTabsCounter({...tabsCounter, pr: count})}/>
                    </div>}
                    {activeRequestTab === 'offer' && <div className="task-body">
                        {userData?.status.type_code === 100 ? <ClientKanbanStepNotifications
                                place={'offer'}
                                accepted={offerData.is_unpaid_tasks ? 3 : offerData.accepted}
                                is_done={offerData['is done']}
                                hasTasks={tasks.find((el: TaskType) => +el.status === 5) ? 1 : 0}
                                notifications={notifications}
                                isWaitIcon={userData?.status.type_code === 100 && (+offerData?.accepted === 1 || +offerData?.accepted === 0)}
                            /> :
                            <OfferHeader
                                statusData={offerStatus(offerData)}
                                statusText={window.location.href.includes('onboarding') ? '' : offerStatus(offerData).text}
                                statusBackground={offerStatus(offerData).color}
                                isWaitIcon={userData?.status.type_code === 100 && (+offerData?.accepted === 1 || +offerData?.accepted === 0)}
                                updated_at={offerData?.updated_at}
                                isMoveToPayment={userData?.status.type_code === 300 && +offerData.accepted === 1}
                                moveToPayment={() => moveToPayment('3')}
                                isMoveToPublic={userData?.status.type_code === 300 && +offerData.accepted === 0}
                                moveToPublic={() => moveToPayment('2')}
                                isStartDelivery={userData?.status.type_code === 300 && +offerData.accepted === 3}
                                startDelivery={() => moveToPayment('5')}
                                isMoveToDone={userData?.status.type_code === 300 && +offerData.accepted === 5 && +offerData['is done'] !== 1}
                                moveToDone={moveToDone}
                                moveToUnDone={moveToUnDone}
                                notifications={notifications}
                                accepted={+offerData?.accepted}
                                is_done={+offerData['is done']}
                                changeStatus={(status: string) => moveToPayment(status)}
                                copyOfferInfoToBuffer={copyOfferInfoToBuffer}
                                isEdit={userData?.status.type_code === 300}
                                isOffer
                                id={id}
                                heading={offerData?.title}
                                text={(userData?.status.type_code === 100 && +offerData.accepted === 0) ? '<em>This offer is being prepared by your VLO. Its description, list of tasks and estimates will become visible to you once the offer is ready. You will receive a notification once it is prepared for your review and confirmation.</em>' : offerData?.description}
                                company_id={offerData?.company?.id}
                                is_archived={offerData.is_archived}
                                moveToArchive={moveToArchive}
                                createGeneralChat={createGeneralChat}
                                chatCount={chatCount}
                            />}

                        <OfferHeading
                            id={id}
                            heading={offerData?.title}
                            step={+offerData['is done'] !== 1 ? switchNextSteps(+offerData.accepted) : ''}
                            text={(userData?.status.type_code === 100 && +offerData.accepted === 0) ? '<em>This offer is being prepared by your VLO. Its description, list of tasks and estimates will become visible to you once the offer is ready. You will receive a notification once it is prepared for your review and confirmation.</em>' : offerData?.description}
                            company_id={offerData?.company?.id}
                            isNextStep={true}
                            isEdit={userData?.status.type_code === 300}
                            data={offerData}
                            isHideContent={isHideContent}
                            setIsHideContent={setIsHideContent}
                        />
                        {!isHideContent && <LegalTeam
                            items={[
                                {
                                    id: offerData?.company?.manager?.user_id,
                                    avatar_path: offerData?.manager?.avatar_path,
                                    fullname: offerData?.manager?.fullname,
                                    title: 'VLO',
                                    type: '300'
                                },
                                ...offerData.lawyers || []
                            ]}
                        />}
                        {!isHideContent && <div onClick={() => setIsHideContent ? setIsHideContent(true) : {}}
                                                className={'offerHeading__show-more'}>
                            Show less <img src={readMoreIcon} alt=""/>
                        </div>}
                    </div>}
                    <div className={'mt15'}>
                        {activeRequestTab === 'offer' && <GroupComments
                            id={id}
                            entity={'offer'}
                            isUnSubscribe={isUnSubscribe}
                            setTabsCounter={setTabsCounter}
                            tabsCounter={tabsCounter}
                            updateChatIndex={updateChatIndex}
                            setChatCount={setChatCount}
                            isHideContent={isHideContent}
                        />}
                    </div>
                </div>
                <div className="task-view__right-side" style={{ flexShrink: 0, width: '480px', maxWidth: '100%' }}>
                    {userData?.status.type_code === 100 && +offerData.accepted === 1 && <OfferClientAccepted date={offerData?.updated_at}/>}
                    <TaskDeliverablesForOffer>
                        <>
                            <TaskTabList
                                offer_id={id}
                                project={offerData.project}
                                tasks={tasks}
                                showStatus={offerData?.accepted !== 0}
                                deleteTask={deleteTask}
                                isAcceptToDeleteTask={+offerData['is done'] !== 1}
                                accepted={offerData?.accepted}
                                activeIndex={activeIndex} setActiveIndex={setActiveIndex}
                                updateParent={getOffer}
                            />
                            {(userData?.status.type_code === 100 && (+offerData?.accepted === 0)) ? <></> : <OfferFooter
                                is_unpaid_tasks={offerData.is_unpaid_tasks}
                                setHaveToBeApproveTasksModal={setHaveToBeApproveModal}
                                payTask={payTasks}
                                payTaskApprove={haveToBeApproveModal}
                                title={offerData.title}
                                pr_id={(tasks || [0])[activeIndex]?.partner_request_id}
                                updateOffer={() => {
                                    setIsAcceptedOffer(true)
                                    getOffer()
                                    setActiveIndex(0)
                                }}
                                copyGeneratedLink={() => {
                                    navigator.clipboard.writeText(outsideOfferLink)
                                    toastifySuccess('Link copied to buffer')
                                }}
                                totalPrice={(tasks || [])[activeIndex]?.total}
                                totalCreditPrice={(tasks || [])[activeIndex]?.credit_total}
                                accepted={offerData?.accepted}
                                onClick={acceptOffer}
                                moveToPayment={(status: string) => moveToPayment(status)}
                                is_done={+offerData['is done']}
                                isUnSubscribe={isUnSubscribe}
                            />}
                        </>
                    </TaskDeliverablesForOffer>
                </div>
            </div>
        </div>
    )
}