import {useContext, useEffect, useState} from "react";
import customAxios from "../../../service/api/interceptors";
import {MainContextProvider} from "../../../context/MainContext";
import {useHistory, useLocation} from "react-router-dom";
import {FollowUpType} from "./FollowUpList";

export const useFollowUpList = () => {
    const location = useLocation()
    const history = useHistory()
    const searchParams = new URLSearchParams(location.search);
    const { setLoading: setLoader, userData } = useContext(MainContextProvider)
    const [companies, setCompanies] = useState([])
    const [loading, setLoading] = useState(false)
    const [items, setItems] = useState<FollowUpType[]>([])
    const [count, setCount] = useState(0)
    const [modalConfig, setModalConfig] = useState<{ id?: number, data?: FollowUpType } | null>(null)
    const [clientCallLink, setClientCallLink] = useState("")

    const getClientCallLing = async () => {
        if (userData?.status.type_code !== 100) return

        try {
            const { status, data } = await customAxios.get(`/onboarding/get-kanban-calendly-widget`)
            if (status === 200) setClientCallLink(data)
        } catch (e) {}
    }

    const getData = async () => {
        const dateMonthAgo = new Date();
        dateMonthAgo.setMonth(dateMonthAgo.getMonth() - 1);

        const page = searchParams.get("page") || 1
        const start_date = searchParams.get("start_date") ? `&from_timestamp=${Number(searchParams.get("start_date")) / 1000}` : `&from_timestamp=${+(dateMonthAgo) / 1000}`
        const end_date = searchParams.get("end_date") ? `&to_timestamp=${(Number(searchParams.get("end_date")) + 86400000) / 1000}` : `&to_timestamp=${(+(new Date()) + 86400000) / 1000}`
        const company = searchParams.get("company_id") ? `&company_id=${searchParams.get("company_id")}` : ''
        const types = searchParams.get("call_type") ? `&types[]=${searchParams.get("call_type")}` : ""

        setLoading(true)
        try {
            const { status, data } = await customAxios.get(`/call-follow-up/list?page=${page}&per_page_count=10${types}${start_date}${end_date}${company}`)
            if (status === 200) {
                setItems(data.call_follow_ups)
                setCount(data.count)
            }
        } catch (e) {}
        setLoading(false)
    }

    useEffect(() => { getClientCallLing() },[])

    useEffect(() => {
        setLoader(false)
        document.title = "Calls & Follow-ups"
        getData()
    }, [location])

    const updateParam = (paramName: string, newValue: string) => {
        if (searchParams.has(paramName)) {
            searchParams.set(paramName, newValue);
        } else {
            searchParams.append(paramName, newValue);
        }
        history.push(`${window.location.pathname}?${searchParams.toString()}`);
    }

    return {
        companies,
        items,
        loading,
        updateParam,
        searchParams,
        count,
        modalConfig,
        setModalConfig,
        getData,
        isClient: userData?.status.type_code === 100,
        clientCallLink,
        userData
    }
}