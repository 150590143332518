import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRangePicker } from 'react-date-range';
import React, {useEffect, useRef, useState} from "react";
import {useOnClickOutside} from "../../../hooks/useOnClickOutside";
import "./platform-date-range-picker.scss"
import {CSSTransition} from "react-transition-group";
import icon from "../../../assets/images/calendarBlue.svg"
import { addYears } from 'date-fns';
import moment from "moment";

type SelectionRange = {
    startDate: Date;
    endDate: Date;
    key: string;
}

type PlatformDateRangePickerType = {
    label?: string
    startDate: Date
    endDate: Date
    onChange: (key: SelectionRange) => void
    className?: string
}

export const PlatformDateRangePicker: React.FC<PlatformDateRangePickerType> = ({ startDate, endDate, onChange, label, className }) => {
    const [isOpen, setIsOpen] = useState(false)
    const dropdownRef = useRef<HTMLDivElement>(null)
    useOnClickOutside({ ref: dropdownRef, handler: () => setIsOpen(false) })

    function handleSelect(ranges: any){
        onChange(ranges.selection)
    }

    return (
        <div ref={dropdownRef} className={`${className} platform-date-range-picker`}>
            {label && <span className={"platform-date-range-picker__label"}>
                {label}
            </span>}
            <div onClick={() => setIsOpen(!isOpen)} className="platform-date-range-picker__input">
                {moment(startDate).format("DD/MM/YYYY")} - {moment(endDate).format("DD/MM/YYYY")} <img src={icon} alt=""/>
            </div>
            <CSSTransition in={isOpen} timeout={{ enter: 200, exit: 200 }} classNames={'my-node'} unmountOnExit>
                <div className={"platform-date-range-picker__dropdown"}>
                    <DateRangePicker
                        ranges={[{
                            startDate, endDate, key: "selection"
                        }]}
                        onChange={handleSelect}
                    />
                </div>
            </CSSTransition>
        </div>
    )
}