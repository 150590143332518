import backIcon from "./icons/back.svg"
import deleteIcon from "./icons/delete.svg"
import fileIcon from "./icons/uploadFile.svg"
import imgIcon from "./icons/img.svg"
import delFileIcon from "./icons/deleteFile.svg"
import pdfIcon from "./icons/file.svg"
import {useFollowUpView} from "./useFollowUpView";
import "./follow-up-view.scss"
import moment from "moment";
import {FollowUpCallTypes} from "../FollowUpList/FollowUpList";
import {ReactEditor} from "../../../components/UI";

export const FollowUpView = () => {
    const {
        item,
        isEditMode,
        setIsEditMode,
        isClient,
        isShowPublish,
        updateDescription,
        updateItem,
        publishItem,
        tch,
        handleFileChange,
        files,
        truncateStringMiddle,
        downloadFile,
        deleteFile
    } = useFollowUpView()

    return (
        <div className={"follow-up-view"}>
            <div onClick={() => window.history.back()} className={"follow-up-view__back"}>
                <img src={backIcon} alt=""/>
                Back
            </div>
            <div className={"flex justifySpaceBetween alignStart"}>
                <div className="follow-up-view__content">
                    <div className="follow-up-view__heading">
                        <h4>{(item.business_goals || [])[0]?.title || ""}</h4>
                        {!isClient && <div className={"follow-up-view__actions"}>
                            {/*{!isEditMode && <div className="follow-up-view__actions-delete mr10">
                                <img src={deleteIcon} alt=""/>
                            </div>}*/}
                            {!isEditMode && <button
                                onClick={() => setIsEditMode(true)}
                                className="follow-up-view__actions-edit mr10">
                                EDIT
                            </button>}
                            {isEditMode && <button
                                onClick={updateItem}
                                disabled={tch}
                                style={{ background: "#2361FF" }}
                                className="btn-black mr10 text-uppercase follow-up-view__actions-publish">
                                    Save
                            </button>}
                            <button disabled={!isShowPublish} onClick={publishItem} className="btn-black text-uppercase follow-up-view__actions-publish">
                                PUBLISH{!isShowPublish ? "ed" : ""}
                            </button>
                        </div>}
                    </div>
                    <div className="follow-up-view__body">
                        <div className="follow-up-view__body-heading" style={{ background: isEditMode ? "#2361ff" : "#628F7C" }}>
                            <div className={"flex"}><span className={"mr10"}>Follow-up</span> {isEditMode ? <><span style={{ fontSize: "20px", lineHeight: "11px", marginRight: "12px" }}>&#183;</span>EDIT MODE</> : ""}</div>
                        </div>
                        {!isEditMode && <div className={"follow-up-view__body-html task-body__description-block task-body__description"}>
                            <p dangerouslySetInnerHTML={{__html: item.description}}/>
                        </div>}
                        {isEditMode && <div className={"follow-up-view__body-html"}>
                            <ReactEditor
                                value={item.description}
                                onChange={(text) => updateDescription(text)}
                                valid={true}
                            />
                        </div>}
                    </div>
                </div>
                <div className="follow-up-view__sidebar">
                    <div className="follow-up-view__call-info">
                        <div className="follow-up-view__call-info-heading">
                            Call INFO
                        </div>
                        <div className="follow-up-view__call-info-body">
                            <div className="follow-up-view__call-info-body-info">
                                <span>Company</span>
                                <p>{item?.company?.name}</p>
                            </div>
                            <div className="follow-up-view__call-info-body-info">
                                <span>Project</span>
                                <p>{(item.business_goals || [])[0]?.title || "-"}</p>
                            </div>
                            <div className="follow-up-view__call-info-body-info">
                                <span>When</span>
                                <p>{moment(item.date * 1000).format("DD/MM/YYYY")}</p>
                            </div>
                            <div className="follow-up-view__call-info-body-info">
                                <span>Type</span>
                                <p>{FollowUpCallTypes[+item?.type]}</p>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: isClient && files.length === 0 ? "none" : "" }} className="follow-up-view__files">
                        <div className="follow-up-view__files-heading">
                            <span>Files</span>
                            {!isClient && <label className={"follow-up-view__files-upload"}>
                                <img src={fileIcon} alt=""/>
                                <div>
                                    <b>Add file</b>
                                    <p>(max size 5 mb)</p>
                                </div>
                                <input type={"file"} onChange={handleFileChange}/>
                            </label>}
                        </div>
                        {files.length > 0 && <div className="follow-up-view__files-list">
                            {
                                files.map(item => (
                                    <div key={item.id} className="follow-up-view__files-item">
                                        <div className="follow-up-view__files-item-left">
                                            <div className="follow-up-view__files-item-icon">
                                                <img src={item.name.includes("pdf") ? pdfIcon : imgIcon} alt=""/>
                                            </div>
                                            <span
                                                title={item.name_to_show}>{truncateStringMiddle(item.name_to_show)}</span>
                                        </div>
                                        <div className="follow-up-view__files-item-right">
                                            <button onClick={() => downloadFile(item.id, item.name)}>Download</button>
                                            {!isClient &&
                                                <img onClick={() => deleteFile(item.id)} src={delFileIcon} alt=""/>}
                                        </div>
                                    </div>
                                ))
                            }
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    )
}