import {ChangeEvent, useEffect, useState} from "react";
import {CompanyType} from "../../../types/company.type";
import customAxios from "../../../service/api/interceptors";
import {BusinessGoalType} from "../../BusinessGoals/BusinessGoal.type";
import moment from "moment";
import {controlErrorFunc} from "../../../components/UI/ControlValid/controlErrorValid";
import {toastifyError} from "../../../service/toastify/toastify";
import {FollowUpType} from "../FollowUpList/FollowUpList";

type ValuesType = {
    company_id: null | number,
    type: number,
    status: number,
    description: string,
    date: string,
    business_goal_id: null | number
    business_goals_list?: number[]
}

const initialValues = {
    company_id: null,
    type: 0,
    status: 0,
    description: "",
    date: moment(new Date()).format("YYYY-MM-DD"),
    business_goal_id: null
}

export const UseFollowUpModal = (close: (key?: boolean) => void, id?: number, isOpen?: boolean, fappdata?: FollowUpType) => {
    const [values, setValues] = useState<ValuesType>({
        company_id: null,
        type: 0,
        status: 0,
        description: "",
        date: moment(new Date()).format("YYYY-MM-DD"),
        business_goal_id: null
    })

    const [companies, setCompanies] = useState<CompanyType[]>([])
    const [projects, setProjects] = useState<BusinessGoalType[]>([])
    const [errors, setErrors] = useState<{[key: string]: string}>({})

    const getProjects = async (id: number) => {
        const { status, data } = await customAxios.get(`/company/${id}/business-goals`)
        if (status === 200) {
            setProjects(data)
        }
    }

    const getData = async () => {
        try {
            const { status, data } = await customAxios.get(`/company/company/get-companies`)
            if (status === 200) {
                setCompanies(data)
            }

            if (id && fappdata) {
                getProjects(fappdata.company_id)
                setValues({
                    ...values,
                    company_id: fappdata.company_id || 0,
                    description: fappdata.description || "",
                    date: moment(fappdata.date * 1000).format("YYYY-MM-DD"),
                    type: fappdata?.type,
                    status: fappdata?.status,
                    business_goal_id: fappdata.business_goals[0]?.id
                })
            }
        } catch (e) {}
    }

    useEffect(() => {
        if (!isOpen) {
            setValues(JSON.parse(JSON.stringify(initialValues)))
        } else {
            getData()
        }
    }, [isOpen])

    const createData = async () => {
        const validReq = controlErrorFunc({ obj: values, config: {
                company_id: { required: true },
                date: { required: true }
            }
        })

        if(Object.keys(validReq).length > 0) {
            setErrors(validReq)
            toastifyError('Please correctly fill all fields in the form')
            return false;
        }
        setErrors({})

        const { company_id, date, description, business_goal_id, type, status } = values

        const postData: ValuesType = {
            company_id, type, status, description, date: (+(new Date(date)) / 1000) + "", business_goals_list: [], business_goal_id
        }
        if (business_goal_id) {
            postData.business_goals_list = [business_goal_id]
        }

        const { status: postStatus } = id ?
            await customAxios.post(`/call-follow-up/${id}`, postData) :
            await customAxios.post(`/call-follow-up`, postData)
        if (postStatus === 200) {
            close(true)
        }
    }

    const changeCompanyId = (id: number) => {
        const business_goal_id = (String(values.company_id) !== id + "") ? null : values.business_goal_id
        setValues({ ...values, company_id: id, business_goal_id })
        if (!!id) getProjects(id)
    }

    const changeValues = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setValues((prevValues: any) => ({
            ...prevValues,
            [event.target.name]: value,
        }));
    };

    return {
        values,
        setValues,
        changeValues,
        companies,
        changeCompanyId,
        errors,
        createData,
        projects
    }
}