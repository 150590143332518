import {UseFollowUpModal} from "./useFollowUpModal";
import ModalContainer from "../../../components/ModalContainer";
import "./follow-up-modal.scss"
import {Input, ReactSelect} from "../../../components/UI";
import {followUpCallTypesArray, FollowUpType} from "../FollowUpList/FollowUpList";
import React from "react";

type FollowUpModalType = {
    isOpen: boolean,
    close: (key?: boolean) => void
    id?: number
    data?: FollowUpType
}

export const FollowUpModal: React.FC<FollowUpModalType> = ({ isOpen, close, id, data }) => {
    const {
        values,
        setValues,
        changeValues,
        companies,
        changeCompanyId,
        errors,
        createData,
        projects
    } = UseFollowUpModal(close, id, isOpen, data)

    return (
        <div className={"follow-up-modal"}>
            <ModalContainer isOpen={isOpen} close={() => close(false)}>
                <div>
                    <h3>{id ? "UPDATE CALL" :"ADD NEW CALL"}</h3>
                    <h4>Call Info</h4>
                    <ReactSelect
                        className={"mt15"}
                        label={"Company"}
                        value={values.company_id || ''}
                        options={[
                            { value: "", label: "No selected" },
                            ...companies.map((el) => ({ value: el.id, label: el.name }))
                        ]}
                        onChange={(e) => changeCompanyId(e.value)}
                        errorMessage={errors?.company_id}
                        valid={!errors.company_id}
                    />
                    {projects.length > 0 && <ReactSelect
                        className={"mt15"}
                        label={"Project"}
                        value={values.business_goal_id || ''}
                        options={[
                            {value: "", label: "No selected"},
                            ...projects.map((el) => ({value: el.id, label: el.title}))
                        ]}
                        onChange={(e) => setValues({ ...values, business_goal_id: e.value })}
                    />}
                    <ReactSelect
                        className={"mt15"}
                        label={"Call type"}
                        value={values.type}
                        options={followUpCallTypesArray}
                        onChange={(e) => setValues({ ...values, type: e.value })}
                    />
                    <Input
                        className={"mt15"}
                        label={"Date"}
                        otherProps={{
                            name: "date",
                            type: "date",
                            value: values.date,
                            onChange: changeValues
                        }}
                        errorMessage={errors?.date}
                        valid={!errors.date}
                    />
                    <button
                        onClick={createData}
                        className={"btn-black text-uppercase w100 mt15"}>
                        {id ? 'Update' : `Create`}
                    </button>
                </div>
            </ModalContainer>
        </div>
    )
}