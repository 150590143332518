import React, {useContext, useEffect, useState} from "react";
import "./nps.scss"
import {MainContextProvider} from "../../../context/MainContext";
import axios from "axios";
import {ModalLoader} from "../../../components/other/ModalLoader/ModalLoader";
import ModalContainer from "../../../components/ModalContainer";
import customAxios from "../../../service/api/interceptors";

export function setCookie(name: string, value: string) {
    const d = new Date();
    d.setTime(d.getTime() + (14*24*60*60*1000));
    const expires = "expires=" + d.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
}

function getCookie(name: string) {
    const nameEq = name + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for(let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(nameEq) === 0) {
            return c.substring(nameEq.length, c.length);
        }
    }
    return "";
}

function getDiffDays(givenTimestamp: number) {
    const givenDate = new Date(givenTimestamp * 1000);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const diff = +today - +givenDate;
    return Math.floor(diff / (1000 * 60 * 60 * 24));
}

export const NpsModal: React.FC = () => {
    const { setLoading, userData } = useContext(MainContextProvider)
    const [showModal, setShowModal] = useState(false)
    const [containerLoading, setContainerLoading] = useState(false)
    const [npsId, setNpsId] = useState(null)

    const setShowRequest = async () => {
        // showed-request
        const { status, data } = await axios.post(`${process.env.REACT_APP_STRIPE_API_URL}/nps/showed-request`, {
            platform_user_id: userData?.user_id,
        })
    }

    const getData = async () => {
        const subscription = await customAxios.get(`/user/client/info/subscriptions`)
        const activeSubscription = subscription.data.filter((el: { active: number }) => +el.active === 1)

        //if (activeSubscription.length > 0) {
            const { status, data } = await axios.post(`${process.env.REACT_APP_STRIPE_API_URL}/platform-user`, {
                name: userData?.name,
                email: userData?.email,
                // email: "techua.com@gmail.com",
                first_name: userData?.firstname,
                platform_user_id: userData?.user_id,
                quantity: getDiffDays(+activeSubscription[0].activate_date),
                //quantity: 100,
                companies: userData?.companies || []
            })
            if (data && data.showQuiz) {
                setTimeout(() => setShowModal(true), 5000)
                if (!getCookie('hide-nps')) setShowRequest().then()
            }
        //}
    }

    useEffect(() => {
        if (userData) getData().then()
        setLoading(false)
    }, [userData])

    const updateData = async (value: string) => {
        // window.open(`/update-nps?nps=${value}&user-id=${userData?.user_id}`)

        //window.location.href = `/update-nps?nps=${value}&user-id=${userData?.user_id}`
        setContainerLoading(true)
        const { status, data } = await axios.post(`${process.env.REACT_APP_STRIPE_API_URL}/nps`, {
            user_id: userData?.user_id, value
        })
        if (status === 201) {
            setShowModal(false)
            setNpsId(data.id)
            window.open(`/update-nps?nps_id=${data.quiz.id}&user-nps-id=${data?.user_id}&value=${value}&user-id=${userData?.user_id}`)
        }
        setContainerLoading(false)
    }

    return (
        <div>
            {!getCookie('hide-nps') && <div className={`${showModal && 'nps-show'} nps-dropdown`}>
                {containerLoading && <ModalLoader/>}
                <div className="nps-dropdown__container">
                    <h4>How likely are you to recommend Legal Nodes to a friend or colleague?</h4>
                    <div className="nps-dropdown__numbers">
                        <div onClick={() => updateData('1')} className="nps-dropdown__number" style={{zIndex: 3, background: '#FF5C5C'}}>1</div>
                        <div onClick={() => updateData('2')} className="nps-dropdown__number" style={{zIndex: 3, background: '#FF5C5C'}}>2</div>
                        <div onClick={() => updateData('3')} className="nps-dropdown__number" style={{zIndex: 3, background: '#FF5C5C'}}>3</div>
                        <div onClick={() => updateData('4')} className="nps-dropdown__number" style={{zIndex: 3, background: '#FF5C5C'}}>4</div>
                        <div onClick={() => updateData('5')} className="nps-dropdown__number" style={{zIndex: 3, background: '#FF5C5C'}}>5</div>
                        <div onClick={() => updateData('6')} className="nps-dropdown__number" style={{zIndex: 3, background: '#FF5C5C'}}>6</div>
                        <div onClick={() => updateData('7')} className="nps-dropdown__number" style={{zIndex: 2, background: '#fdc729'}}>7</div>
                        <div onClick={() => updateData('8')} className="nps-dropdown__number" style={{zIndex: 2, background: '#fdc729'}}>8</div>
                        <div onClick={() => updateData('9')} className="nps-dropdown__number" style={{zIndex: 1, background: '#5d8c51'}}>9</div>
                        <div onClick={() => updateData('10')} className="nps-dropdown__number" style={{zIndex: 1, background: '#5d8c51'}}>10</div>
                    </div>
                    <div className={'nps-dropdown__sub'}>
                        <p>Not likely</p>
                        <p>Very likely</p>
                    </div>
                    <div onClick={() => {
                        setShowModal(false)
                        setCookie('hide-nps', 'true')
                    }} className="nps-close">&#10005;</div>
                </div>
            </div>}
            {npsId && <ModalWithQuestions npsId={npsId} />}
        </div>
    )
}

const ModalWithQuestions = ({ npsId }: { npsId: string }) => {
    return (
        <ModalContainer isOpen={true}>
            <div>
                <h2 style={{ fontSize: '20px', textAlign: 'center' }}>Thanks for your answer</h2>
            </div>
        </ModalContainer>
    )
}