import React, {useEffect, useRef} from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
//import 'quill-table-ui/dist/index.css';

interface SimpleEditorProps {
    value: string;
    onChange: (value: string) => void;
}

export const NewEditor = ({ value, onChange }: SimpleEditorProps) => {
    const isMounted = useRef(false);

    useEffect(() => {
        isMounted.current = true;
    }, []);

    // @ts-ignore
    const handleChange = (content, delta, source, editor) => {
        if (isMounted.current && source === 'user') {
            // @ts-ignore
            onChange(content, delta, source, editor);
        }
    };
    /*const modules = {
        toolbar: [
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'indent': '-1' }, { 'indent': '+1' }],
            [{ 'align': [] }],
            ['blockquote'],
            ['link', 'image'],
            ['table'],
            ['clean']
        ],
    };*/

    const modules = {
        toolbar: [
            // [{ 'header': [1, 2, false] }],
            ['bold', 'italic', 'underline', 'link'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            //['link'],
            //['image'],
            ['clean']
        ],
    };

    return (
        <ReactQuill
            className="quill-editor"
            style={{ maxWidth: '100%', wordBreak: 'break-word' }}
            theme="snow"
            value={value}
            onChange={handleChange}
            modules={modules}
        />
    );
}